('use strict');

/**
 * @class {ResponsiveElements} ResponsiveElements Class specification
 */

class ResponsiveElements {
  constructor() {
    /**
     * Define default properties
     */
    this.props = {
      mq: null,
      flyoutParentDesktopId: 'c-header-flyout__wrap--desktop',
      flyoutParentMobileId: 'c-header-flyout__wrap--mobile',
      flyoutsClass: '.js-header-flyouts',
      flyoutsEl: null,
      flyoutsParentDesktopEl: null,
      flyoutsParentMobileEl: null,
      breakpoint: null
    };
  }

  /**
   * Register event handler
   */
  registerHandler() {
    window.addEventListener('resize', () => this.onResize());
  }

  /**
   * Get DOM elements for later DOM manipulation
   */
  findDomElements() {
    // Get flyouts and parent divs
    this.props.flyoutsEl = document.querySelector(
      this.props.flyoutsClass
    );
    this.props.flyoutsParentMobileEl = document.getElementById(
      this.props.flyoutParentMobileId
    );
    this.props.flyoutsParentDesktopEl = document.getElementById(
      this.props.flyoutParentDesktopId
    );
  }

  /**
   * Check if it's mobile or desktop breakpoints and rearrange the language list
   * and the search form elements accordingly to these breakpoints
   */
  rearrangeElems() {
    let isDesktop = this.props.breakpoint.getDesktop();

    if (!isDesktop) {
      // Set flyouts for mobile layout
      this.manipulateDomElems(
        this.props.flyoutsEl,
        this.props.flyoutsParentDesktopEl,
        this.props.flyoutsParentMobileEl
      );
    }

    if (isDesktop) {
      // Set flyouts for desktop layout
      this.manipulateDomElems(
        this.props.flyoutsEl,
        this.props.flyoutsParentMobileEl,
        this.props.flyoutsParentDesktopEl
      );
    }
  }

  /**
   * Remove elememt from a parent container and append it to a new parent container
   *
   * @param  {Node} el
   * @param  {Node} removeFromEl
   * @param  {Node} appendToEl
   * @returns nothing
   */
  manipulateDomElems(el, removeFromEl, appendToEl) {
    if (el && removeFromEl && appendToEl) {
      if (!appendToEl.contains(el)) {
        el = removeFromEl.removeChild(el);
        appendToEl.appendChild(el);
      }
    }
  }

  /**
   * Check on window resize if the last measured window width is not equal to the current
   * and trigger function rearrangeElems
   * set the last window width to be the current window width
   */
  onResize() {
    const mqCurrent = this.props.breakpoint.getMq();
    if (this.props.mq !== mqCurrent) {
      this.props.mq = mqCurrent;
      this.rearrangeElems();
    }
  }

  init(_bp) {
    this.props.breakpoint = _bp;
    this.registerHandler();
    this.findDomElements();
    this.props.mq = this.props.breakpoint.getMq();
    this.rearrangeElems();
  }
}

export default ResponsiveElements;
