import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

('use strict');

/**
 *  Class SmoothScroll handles the following:
 *  - scroll to targets defined by hash value of trigger href
 *  - if trigger href="#top" window will scroll to top of the page
 */

class SmoothScroll {
  constructor() {
    this.props = {
      scrollToTriggerSel: '.js-scroll-trigger',
      $scrollToTriggerList: null,
      isScrollingBodyClass: 'is-smooth-scrolling'
    };
  }

  init() {
    this.findDomElements();
    if (!this.props.$scrollToTriggerList) {
      return;
    }
    this.registerHandler();
  }

  /**
   * get list of scrollTo triggers
   */
  findDomElements() {
    this.props.$scrollToTriggerList = document.querySelectorAll(this.props.scrollToTriggerSel);
    this.props.bodyElement = document.querySelector('body');
  }

  /**
   *  set handler
   */
  registerHandler() {
    this.props.$scrollToTriggerList.forEach(item => {
      item.addEventListener('click', event => {
        event.preventDefault();
        const targetId = '#' + item.hash.substr(1);
        this.scrollTo(targetId);
        item.blur();
      });
    });
  }

  /**
   *  set scrollTo behavior
   */
  scrollTo(targetObjectId) {
    const target = targetObjectId;
    const $target = document.querySelector(targetObjectId);
    const isModule = targetObjectId.substring(0, 3) === '#o-' ? true : false;
    const moduleGap = 80;
    let checkIfScrollToIsFinished;
    let distance;

    if (target !== undefined || targetObjectId === '#top') {
      if ($target !== null) {
        distance = $target.offsetTop;

        if (isModule) {
          distance += moduleGap;
        }

        this.setHistory(targetObjectId);
      } else {
        distance = 0;
      }

      this.props.bodyElement.classList.add(this.props.isScrollingBodyClass);

      window.scrollTo({
        top: distance,
        behavior: 'smooth'
      });

      checkIfScrollToIsFinished = setInterval(() => {
        if (distance === window.scrollY) {
          this.props.bodyElement.classList.remove(this.props.isScrollingBodyClass);
          clearInterval(checkIfScrollToIsFinished);
        }
      }, 25);
    }
  }

  /**
   *  set history / location hash
   */
  setHistory(targetObjectId) {
    if (history.pushState) {
      history.pushState(null, null, targetObjectId);
    } else {
      location.hash = targetObjectId;
    }
  }
}

export default SmoothScroll;
