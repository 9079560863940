('use strict');

/*
 * Helper class for Dev Tools
 */
class DevHelper {
  constructor() {
    this.desktopClass = 'is-desktop';
    this.breakpoint = null;
    this.$mqIndicator = null;
    this.$mqdesktop = null;
  }

  init(_bp) {
    this.breakpoint = _bp;

    document.addEventListener('DOMContentLoaded', () => {
      this.initGrid();
      this.registerHandler();
    });
  }

  /*
   * shows the bootstrap grid on patternlab rendered templates
   */
  initGrid() {
    const gh = document.getElementsByClassName('grid-helper');
    this.$mqIndicator = document.querySelectorAll('.grid-helper .mq-indicator')[0];

    if (gh.length && this.$mqIndicator) {
      const $gridHelper = gh[0];
      document.addEventListener('keyup', event => {
        // if the letter 'g' is pressed
        if (event.keyCode === 71) {
          $gridHelper.classList.toggle('show');
        }
      });

      this.updateUi();
    }
  }

  updateUi() {
    this.$mqIndicator.innerHTML = this.breakpoint.getMq ? this.breakpoint.getMq() : ' ';
    this.breakpoint.getDesktop()
      ? this.$mqIndicator.classList.add(this.desktopClass)
      : this.$mqIndicator.classList.remove(this.desktopClass);
  }

  registerHandler() {
    /* add resize handler */
    if (this.$mqIndicator) {
      window.addEventListener('resize', () => this.updateUi());
    }
  }
}

export default DevHelper;
