('use strict');

/**
 * @class {Numbers} Numbers Class specification
 */

class Numbers {
  constructor() {
    /**
     * Define default properties
     */
    this.props = {};
  }

  /**
   * Clamp function
   *
   * @param {number} num
   * @param {number} min
   * @param {number} max
   */
  clamp(num, min, max) {
    return Math.min(Math.max(num, min), max);
  }
}

export default Numbers;
