('use strict');

class Bookmarks {
  constructor() {
    this.props = {
      breakpoint: null,
      elementSel: '.js-bookmarks',
      ctaSel: '.js-bookmarks-cta',
      noteSel: '.js-bookmarks-note',
      countSel: '.js-bookmarks-count',
      btnSel: '.js-bookmarks-btn',
      emailSel: '.js-bookmarks-email',
      downloadSel: '.js-bookmarks-download',
      templateSel: '.js-bookmarks-template',
      productSel: '.js-bookmarks-product',
      emptySel: '.js-bookmarks-empty',
      collectionSel: '.js-bookmarks-collection',
      productListSel: '.js-bookmarks-list',
      localStorageBookmarks: 'coroplast-bookmarks',
      lang: 'de',
      addedClass: 'is-added',
      activeClass: 'is-active',
      animationClass: 'is-animation',
      visibleClass: 'is-visible',
      readyClass: 'is-bookmark-ready'
    };
  }

  init(_bp, _overlay) {
    this.el = document.querySelector(this.props.elementSel);

    if (!this.el) return;

    this.props = this.setProps(JSON.parse(this.el.dataset.jsProps));
    this.props.breakpoint = _bp;
    this.throttleTimer = null;
    this.overlay = _overlay;
    this.bookmarks = {};
    this.bookmarks.list = [];
    this.cta = document.querySelectorAll(this.props.ctaSel);
    this.ctaEnhanced = [];
    this.note = this.el.querySelectorAll(this.props.noteSel);
    this.count = this.el.querySelectorAll(this.props.countSel);
    this.btn = this.el.querySelectorAll(this.props.btnSel);
    this.template = this.el.querySelector(this.props.templateSel);
    this.productTemplate = this.el.querySelector(this.props.productSel);
    this.productList = this.el.querySelector(this.props.productListSel);
    this.emailLink = document.querySelectorAll(this.props.emailSel);
    this.downloadLink = document.querySelectorAll(this.props.downloadSel);
    this.orginalDownloadLink = null;
    this.orginalEmailLink = null;

    // check if cookiebot is accept
    this.checkCookiebot();
  };

  /**
   *  async check if cookiebot is accept
   */
  checkCookiebot() {
    const that = this;
    window.addEventListener('CookiebotOnAccept', () => {
      if (Cookiebot.consent.necessary) {
        // get saved data from localstorage
        that.checkSavedParams();

        // add event handler
        that.registerHandler();

        // ready
        this.el.classList.add(this.props.readyClass);
      }
    }, false);

    window.addEventListener('CookiebotOnDecline', () => {
      if (Cookiebot.consent.necessary) {
        // get saved data from localstorage
        that.checkSavedParams();

        // add event handler
        that.registerHandler();

        // ready
        this.el.classList.add(this.props.readyClass);
      }
    }, false);
  }

  /**
   *  set event handler to elements
   */
  registerHandler() {
    this.el.addEventListener('closeOverlay', (e) => this.onCloseOverlay(e));
    this.el.addEventListener('openOverlay', (e) => this.onOpenedOverlay(e));

    window.addEventListener('resize', () => this.onResize());
    document.addEventListener('updateTeaser', (e) => this.onUpdateTeaser(e));

    // loop though all buttons add click handler
    [...this.btn].forEach(btn => {
      btn.addEventListener('click', (e) => this.onOpenOverlay(e));
    });

    // register click handler on cta buttons
    this.onUpdateTeaser();
  }

  /**
   * Check on window resize
   */
  onResize() {
    //console.log('resize');
  }

  /**
   * register click handler to recreated teaser cta button
   */
  onUpdateTeaser() {
    const that = this;

    // remove click handler
    this.ctaEnhanced.forEach(enhanced => {
      enhanced.cta.removeEventListener('click', enhanced.clickHandler);
    });

    this.cta = document.querySelectorAll(this.props.ctaSel);
    this.ctaEnhanced = [];

    this.cta.forEach(cta => {
      this.ctaEnhanced.push({cta, clickHandler(e) {that.onClickCta(cta);}});
    });

    // add clickhandler
    this.ctaEnhanced.forEach(enhanced => {
      enhanced.cta.addEventListener('click', enhanced.clickHandler);
    });
  }

  /**
   * Clone existing dummy teaser and set the params from product data obj
   *
   * @param {Object} productData
   */
  createProductTeaser(productData) {
    // Create a clone of example product
    let product = this.productTemplate.cloneNode(true);
    let props = { 'uid': productData.uid };
    product.setAttribute('data-js-props', JSON.stringify(props));

    let link = product.querySelector('.c-product-teaser');
    link.setAttribute('href', productData.link.uri);
    link.setAttribute('target', productData.link.target);
    link.setAttribute('title', productData.link.title);

    let topline = product.querySelector('.c-product-teaser__topline').cloneNode(true);
    let headline = product.querySelector('.c-product-teaser__headline');
    headline.innerHTML = productData.headline;
    if (productData.topline) {
      topline.innerHTML = productData.topline;
      headline.prepend(topline);
    }
    product.querySelector('.c-product-teaser__text').innerHTML = productData.teaser;
    let img = product.querySelector('.c-product-teaser__img');
    img.setAttribute('src', productData.image.default.uri.default);

    if (productData.image.metaData.alternative) {
      img.setAttribute('alt', productData.image.metaData.alternative);
    }
    product.querySelector(this.props.ctaSel).setAttribute('data-js-props', JSON.stringify(productData));
    this.productList.prepend(product);

    // register click handler on cta buttons
    this.onUpdateTeaser();
    this.updateOverlayLinks();

    this.update();
  }

  /**
   *  event handler
   *
   *  @param {cta} element
   */
  onClickCta(cta) {
    cta.blur();
    let obj = JSON.parse(cta.dataset.jsProps);

    if (cta.classList.contains(this.props.addedClass)) {
      this.removeItem(obj.uid);
    } else {
      this.addItem(obj);
    }
  }

  /**
   * Add product data to list and storage
   *
   * @param {object} obj
   */
  addItem(obj) {
    this.bookmarks.list.push(obj);

    //safe obj in localstorage check if necessary cookies is accept
    if (typeof Cookiebot != 'undefined' &&
      typeof Cookiebot.consent != 'undefined' &&
      typeof Cookiebot.consent.necessary != 'undefined' &&
      Cookiebot.consent.necessary) {
      localStorage.setItem(this.props.localStorageBookmarks +'-'+ this.props.lang, JSON.stringify(this.bookmarks));
    }

    this.update();
  }

  /**
   * Remove product data from list and storage
   *
   * @param {string} id
   */
  removeItem(id) {
    this.bookmarks.list = this.bookmarks.list.filter((item) => {

      if (item.uid != id) {
        return item;
      }
    });

    //safe obj in localstorage check if necessary cookies is accept
    if (typeof Cookiebot != 'undefined' &&
      typeof Cookiebot.consent != 'undefined' &&
      typeof Cookiebot.consent.necessary != 'undefined' &&
      Cookiebot.consent.necessary) {
      localStorage.setItem(this.props.localStorageBookmarks +'-'+ this.props.lang, JSON.stringify(this.bookmarks));
    }

    this.update();
  }

  /**
   * update elements cta / btn & counter / collection in overlay
   */
  update() {
    // loop though all inputs and add event listener on change
    [...this.cta].forEach(cta => {

      let checked = false;
      let uid = JSON.parse(cta.dataset.jsProps).uid;
      this.bookmarks.list.filter((item) => {
        if (item.uid === uid) {
          checked = true;
        }
      });

      checked ? cta.classList.add(this.props.addedClass) : cta.classList.remove(this.props.addedClass);
    });

    // refresh counter
    [...this.count].forEach(count => {

      if (count.textContent !== String(this.bookmarks.list.length)) {
        count.textContent = String(this.bookmarks.list.length);

        [...this.btn].forEach(btn => {
          btn.classList.add(this.props.animationClass);

          setTimeout(() => {
            btn.classList.remove(this.props.animationClass);
          }, 500);
        });
      }
    });

    // update button style
    [...this.btn].forEach(btn => {
      this.bookmarks.list.length > 0 ? btn.classList.add(this.props.activeClass) : btn.classList.remove(this.props.activeClass);
    });

    // update collection
    let collection = this.template.querySelector(this.props.collectionSel);
    let collectedProducts = collection.querySelectorAll(this.props.productSel);

    [...collectedProducts].forEach(product => {
      let remove = true;
      this.bookmarks.list.filter((item) => {
        if (item.uid === JSON.parse(product.dataset.jsProps).uid) {
          remove = false;
        }
      });

      if (remove) product.remove();
    });

    let collectionCta = collection.querySelectorAll(this.props.ctaSel);
    collectionCta.forEach(cta => cta.classList.add(this.props.addedClass));

    if (this.bookmarks.list.length < 1) {
      this.updateOverlay();
    }

    this.updateOverlayLinks();
  }

  /**
   *  check if localstorage exist
   */
  checkSavedParams() {
    //check if necessary cookies is accept
    if (typeof Cookiebot != 'undefined' &&
      typeof Cookiebot.consent != 'undefined' &&
      typeof Cookiebot.consent.necessary != 'undefined' &&
      Cookiebot.consent.necessary) {

      // if session storage facets
      if (localStorage.getItem(this.props.localStorageBookmarks +'-'+ this.props.lang)) {
        let bookmarkStr = localStorage.getItem(this.props.localStorageBookmarks +'-'+ this.props.lang);
        let bookmarksObj = JSON.parse(bookmarkStr);
        this.bookmarks = bookmarksObj;

        // update inputs
        this.update(this.bookmarks);
      }

    } else {
      localStorage.removeItem(this.props.localStorageBookmarks +'-'+ this.props.lang);
    }
  }

  /**
   *  event handler
   *
   *  @param {event} e
   */
  onOpenOverlay(e) {
    this.overlayActive = true;

    let obj = {};
    obj.element = this.template;
    obj.response = this.el;

    this.updateOverlay();

    this.overlay.render(obj);
  }

  /**
   *  event handler
   *
   *  @param {event} e
   */
  onOpenedOverlay(e) {
    this.onUpdateTeaser();
    this.updateOverlayLinks();
  }

  /**
   *  event handler
   *
   *  @param {event} e
   */
  onCloseOverlay(e) {
    this.onUpdateTeaser();
  }

  /**
   *  show / hide elements on overlay, conditioned to collection list empty
   */
  updateOverlay() {
    let empty = this.template.querySelector(this.props.emptySel);
    let collection = this.template.querySelector(this.props.collectionSel);

    // change content in overlay if no products collected
    if (this.bookmarks.list.length > 0) {
      if (empty) empty.classList.remove(this.props.visibleClass);
      if (collection) collection.classList.add(this.props.visibleClass);

      // reset existing list
      this.productList.innerHTML = '';
      [...this.bookmarks.list].forEach(product => {
        this.createProductTeaser(product);
      });

    } else {
      if (empty) empty.classList.add(this.props.visibleClass);
      if (collection) collection.classList.remove(this.props.visibleClass);
    }
  }

  /**
   *  show / hide elements on overlay, conditioned to collection list empty
   */
  updateOverlayLinks() {
    let productIdsReplace = new RegExp('%23%23%23PRODUCT_IDS%23%23%23','g');
    let ampersandReplace = new RegExp('&','g');
    let collectionItemsReplace = new RegExp('%23%23%23COLLECTION_ITEMS%23%23%23','g');
    let productIds = '';
    let collectionItems = '';
    let br = '%0A';
    let comma = '%2C';
    let href = '';

    this.bookmarks.list.forEach((item, i, arr) => {
      let seperator = i===0 ? '' : comma;
      productIds = `${item.uid}${seperator}${productIds}`;
      let title = encodeURI(item.headline);
      let url = encodeURI(item.link.uri);
      url = url.replace( ampersandReplace, '%26' );
      collectionItems = `${title}${br}${url}${br}${br}${collectionItems}`;
    });

    // update download link
    this.downloadLink = document.querySelectorAll(this.props.downloadSel);
    this.downloadLink.forEach(link => {
      this.orginalDownloadLink = this.orginalDownloadLink === null ? link.getAttribute('href') : this.orginalDownloadLink;
      href = this.orginalDownloadLink.replace( productIdsReplace, productIds );
      link.setAttribute('href', href);
    });

    // update link in email
    this.emailLink = document.querySelectorAll(this.props.emailSel);
    this.emailLink.forEach(link => {
      this.orginalEmailLink = this.orginalEmailLink === null ? link.getAttribute('href') : this.orginalEmailLink;
      href = this.orginalEmailLink.replace( productIdsReplace, productIds );
      href = href.replace( collectionItemsReplace, collectionItems);
      link.setAttribute('href', href);
    });
  }

  /**
   * merge default props with props form outside
   */
  setProps(_props) {
    const props = {
      ...this.props,
      ..._props,
    };

    return props;
  }
}

export default Bookmarks;
