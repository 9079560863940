('use strict');

/**
 * @class {VerticalHeight} VerticalHeight Class specification
 */

class VerticalHeight {
  constructor() {
    /**
     * Define default properties
     */
    this.props = {
      vh: null
    };
  }

  /**
   * Register event handler
   */
  registerHandler() {
    window.addEventListener('resize', () => this.onResize());
  }

  setVerticalHeight(height) {
    this.props.vh = height;
  }

  /**
   * get current viewport height
   */
  getVerticalHeight() {
    return this.props.vh;
  }

  /**
   * get current viewport height
   */
  getCurrentHeight() {
    return window.innerHeight * 0.01;
  }

  /**
   * Check on window resize if the last measured window height is not equal to the current
   * set the last window height to be the current window height
   */
  onResize() {
    const vh = this.getCurrentHeight();
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    this.setVerticalHeight(vh);
  }

  /**
   * initialize viewport height
   * register eventhandler
   */
  init() {
    this.setVerticalHeight(this.getCurrentHeight());
    this.registerHandler();
    this.onResize();
  }
}

export default VerticalHeight;
