import lazySizes from 'lazysizes';

('use strict');

/*
 * configures the lazysizes plugin
 * https://github.com/aFarkas/lazysizes
 */
class LazySizes {
  constructor() {}

  /*
   * sets the params wich will be used by lazysizes,
   * which will be invoced automaticly.
   */
  init() {
    if (lazySizes) {
      lazySizes.cfg.lazyClass = 'js-lazyload';
      lazySizes.cfg.loadingClass = 'is-loading';
      lazySizes.cfg.loadedClass = 'has-loaded';
      lazySizes.cfg.expand = '700';
    }
  }
}

export default LazySizes;
