('use strict');

class FunctionBar {
  constructor() {
    this.bar = null;
    this.props = {
      elementSel: '.js-function-bar',
      popupLinkSel: '.js-popup'
    };
  }

  init() {
    var _ = this;

    this.bar = document.querySelectorAll(this.props.elementSel);

    // Map over each bar
    this.bar.forEach( (_item) => {
      let links = _item.querySelectorAll(this.props.popupLinkSel);

      // Map over each link
      links.forEach((_link) => {

        // get url
        let url = _link.getAttribute('href');

        // open popup on click
        _link.addEventListener('click', (event) => {
          event.preventDefault();

          window.open(url, '_blank', 'width=600,height=460');

          // remove focus after click
          let link = document.querySelector(this.props.popupLinkSel);
          link.blur();

        });

      });
    });
  };
}

export default FunctionBar;
