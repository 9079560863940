/*
    This polyfill adds array.unique compatibility to all Browsers supporting ES5:
*/

export default (function polyfillArrayUnique() {
  if (window.Array && !Array.prototype.unique) {
    Array.prototype.unique = function() {
      var a = this.concat();
      for(var i=0; i<a.length; ++i) {
        for(var j=i+1; j<a.length; ++j) {
          if(a[i] === a[j])
            a.splice(j--, 1);
        }
      }
      return a;
    };
  }
})();
