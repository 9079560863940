('use strict');

class ArticleWall {
  constructor() {
    this.props = {
      breakpoint: null,
      resultSel: '.js-article-result',
      itemSel: '.js-article-item',
      highlightSel: '.js-highlight-item',
      categoryBtnSel: '.js-tab-button',
      selectSel: '.js-select',
      loadmoreSel: '.js-loadmore',
      itemsPerPage: 3,
      activeTabClass: 'is-active',
      activeArticleClass: 'is-active-article',
      visibleClass: 'is-visible',
      hiddenClass: 'is-hidden',
    };
    this.el = null;
  }

  /**
   * Initialize
   *
   * @param {Breakpoint} _bp
   * @param {Dom Element} _el
   * @param {obj} _props
   */
  init(_bp, _el, _props) {

    if (!_el) return;

    this.el = _el;
    this.props.breakpoint = _bp;
    this.props = this.setProps(_props);

    // get elements
    this.resultContainer = this.el.querySelector(this.props.resultSel);
    this.allHighlights = this.el.querySelectorAll(this.props.highlightSel);
    this.highlight = null;
    this.allItems = this.el.querySelectorAll(this.props.itemSel);
    this.filteredResults = null;
    this.loadmoreIndex = null;
    this.loadMoreBtn = this.el.querySelector(this.props.loadmoreSel);
    this.categoryBtn = this.el.querySelectorAll(this.props.categoryBtnSel);
    this.select = this.el.querySelector(this.props.selectSel);

    // set vars
    this.ordered = null;

    // Show 'all' items by default
    this.category = 'all';

    if (typeof(this.select) !== 'undefined' && this.select !== null) {
      this.category = this.select.value;
    }

    // add event handler
    this.registerHandler();

    // show items of selected category
    this.changeCategory(this.category);
  };

  /**
   *  set handler
   */
  registerHandler() {
    this.loadMoreBtn.addEventListener('click', (e) => this.onLoadmoreClick(e));

    if (typeof(this.select) !== 'undefined' && this.select !== null) {
      this.select.addEventListener('change', (e) => this.onSelectChange(e));
    }

    this.categoryBtn.forEach((_btn) => {
      //catergory
      let category = _btn.getAttribute('value');
      _btn.addEventListener('click', (e) => this.onCategoryClick(e, category));
    });
  }

  /**
   *  event handler
   *
   *  @param {event} e
   */
  onLoadmoreClick(e) {
    this.loadmoreIndex ++;
    this.loadnext(this.loadmoreIndex);
  }

  /**
   *  event handler
   *
   *  @param {event} e
   */
  onSelectChange(e) {
    let category = this.select.value;
    this.changeCategory(category);
  }

  /**
   *  event handler
   *
   *  @param {event} e
   *  @param {string} category
   */
  onCategoryClick(e, category) {
    this.changeCategory(category);
  }

  /**
   * change category
   */
  changeCategory(category) {
    this.category = category;

    // set active btn
    this.categoryBtn.forEach((_btn) => {
      if (_btn.getAttribute('value') === category) {
        _btn.classList.add(this.props.activeTabClass);
      } else {
        _btn.classList.remove(this.props.activeTabClass);
      }
    });

    if (typeof(this.select) !== 'undefined' && this.select !== null) {
      this.select.value = category;
    }

    // clear results
    this.resultContainer.innerHTML = '';

    // filter the items
    this.filterResults(category);
  }
  /**
   * filter results for category
   *
   *  @param {string} category
   */
  filterResults() {

    // show category highlight
    this.highlight = [...this.allHighlights].filter((_highlight) => {

      _highlight.classList.remove(this.props.visibleClass);

      if (_highlight.dataset.category === this.category || this.category === 'all') {
        _highlight.classList.remove(this.props.hiddenClass);
        return _highlight;

      } else {
        _highlight.classList.add(this.props.hiddenClass);
      }
    });

    // filter the items of category / show all when all
    this.filteredResults = [...this.allItems].filter((_item) => {

      // return item if category is correct
      if (_item.dataset.category === this.category || this.category === 'all') {
        return _item;
      }
    });

    this.loadnext(0);
  }

  /**
   * show next set of items
   *
   *  @param {number} count
   */
  loadnext(index) {
    this.loadmoreIndex = index;
    let barrier = (this.loadmoreIndex * this.props.itemsPerPage) + this.props.itemsPerPage;

    // display frist 6 items
    this.ordered = [...this.filteredResults].filter((_item, i) => {

      if (i < barrier && i >= (this.loadmoreIndex * this.props.itemsPerPage)) {
        let itemClone = _item.cloneNode(true);
        this.resultContainer.appendChild(itemClone);

        return _item;
      }
    });

    this.showResults(barrier);
  }

  /**
   * show results
   *
   *  @param {number} barrier
   */
  showResults(barrier) {
    let results = this.resultContainer.querySelectorAll(this.props.itemSel);

    setTimeout(() => {

      // show highlight teaser
      if (this.highlight && this.highlight[0]) {
        this.highlight[0].classList.add('is-visible');
      }

      // show articles
      [...results].filter((_item) => {
        _item.classList.add(this.props.visibleClass);
      });

      // show / hide button
      if (this.filteredResults.length <= barrier) {
        this.loadMoreBtn.classList.add(this.props.hiddenClass);

      } else {
        this.loadMoreBtn.classList.remove(this.props.hiddenClass);
      }

    }, 200);
  }

  /**
   * merge default props with props form outside
   */
  setProps(_props) {
    const props = {
      ...this.props,
      ..._props,
    };

    return props;
  }
}

export default ArticleWall;
