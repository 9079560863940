/*eslint-disable */
/* Style imports */
import * as style from '../scss/main.scss';


// ie detection
if(navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > -1){
  document.querySelector('body').classList.add('is-IE');
}

// polyfills
import './polyfills/polyfill-append';
import './polyfills/polyfill-closest';
import './polyfills/polyfill-nodelist-forEach';
import './polyfills/polyfill-array-unique';
import './polyfills/polyfill-validity-state';


/* Utilities */
// import Backstop from './modules/backstop';
import Breakpoint from './helper/breakpoint';
import VerticalHeight from './helper/vertical-height';
import DevHelper from './modules/dev-helper';

/* Global libs */
import LazySizes from './modules/lazysizes';
import { FormValidator } from './modules/form-validation';

/* Responsive behaviour */
import ResponsiveElements from './modules/responsive-elements';

/* Navigation */
import MainNav from './navigation/main-nav';
import MobileNav from './navigation/mobile-nav';

/* Components */
import HeaderFlyouts from './components/header-flyout';
import StickyHeader from './components/sticky-header';
import Slider from './components/slider';
import Search from './components/search';
import FunctionBar from './components/function-bar';
import ArticleWall from './components/article-wall';
import ProductFilter from './components/product-filter';
import Overlay from './components/overlay';
import SmoothScroll from './components/smooth-scroll';
import Bookmarks from './components/bookmarks';
import ImageSequence from './components/image-sequence';

/* Content Modules */

function requireAll(r) {
  r.keys().forEach(r);
}

requireAll(require.context('../images/', true, /\.svg$/));

// vendor
import 'bootstrap/js/dist/collapse';
//import 'bootstrap';

/*
 * Entry point of the Frontend Application.
 */
class App {
  /*
   * Create instances of all needed classes.
   * All scripts will be invoced here by calling their init methods.
   */
  init() {
    const verticalHeight = new VerticalHeight();
    const lazysizes = new LazySizes();
    const breakpoint = new Breakpoint();
    const mainNav = new MainNav();
    const mobileNav = new MobileNav();
    const flyouts = new HeaderFlyouts();
    const stickyHeader = new StickyHeader();
    const responsiveElements = new ResponsiveElements();
    const functionBar = new FunctionBar();
    const overlay = new Overlay();
    const smoothScroll = new SmoothScroll();
    const bookmarks = new Bookmarks();

    /**
     * Check for dev-environment(s)
     */
    this.isHeadlessChrome =
      /HeadlessChrome/.test(window.navigator.userAgent);
    const devMode =
      location.hostname === 'localhost' ||
      location.hostname === '127.0.0.1' ||
      location.hostname.indexOf('coro.itools.website') >= 0 ||
      this.isHeadlessChrome
    ;

    lazysizes.init();
    breakpoint.init();
    verticalHeight.init();
    mainNav.init();
    mobileNav.init(breakpoint);
    flyouts.init(breakpoint);
    stickyHeader.init(flyouts, mobileNav);
    responsiveElements.init(breakpoint);
    functionBar.init();
    overlay.init(breakpoint);
    smoothScroll.init();
    bookmarks.init(breakpoint, overlay);

    this.initSlider(breakpoint);
    this.initSearch();
    this.initArticleWall(breakpoint);
    this.initProductFilter(breakpoint, overlay);
    this.initImageSequence(breakpoint);

    if (devMode) {
      this.initDev(breakpoint);
    }

    /**
     * Automatically initializes validation on forms with a specific data attribute or hook class.
     */
    document.addEventListener('DOMContentLoaded', () => {
        // Select all forms with the data-validate attribute or a specific hook class
        const forms = document.querySelectorAll('form[data-form-validator], form.js-form-validator');

        forms.forEach(form => {
            // Initialize the FormValidator for each form
            new FormValidator(form);
        });
    });

    /**
     * Fix history bug in bookmark counter
     */
    window.addEventListener('pageshow', (e) => {
      if (performance.navigation && performance.navigation.type == 2) {
        location.reload();
      }
    });
  }

  /**
   * create muliple instances of sliders
   */
  initSlider(breakpoint) {
    this.slider = document.querySelectorAll('.js-slider');

    // Map over each slider
    this.slider.forEach((_slider) => {

      // extract the data value
      var props = JSON.parse(_slider.dataset.jsProps);
      const slider = new Slider();
      slider.init(breakpoint, _slider.getAttribute('id'), props);
    });
  }

  /**
   * create muliple instances of search
   */
  initSearch() {
    this.$search = document.querySelectorAll('.js-search');

    // Map over each search
    this.$search.forEach((_search) => {

      const search = new Search();
      search.init($(_search));
    });
  }

  /**
   * create muliple instances of article wall
   */
  initArticleWall(breakpoint) {
    this.articleWall = document.querySelectorAll('.js-article-wall');

    // Map over each article wall
    this.articleWall.forEach((_articleWall) => {
      const articleWall = new ArticleWall();

      // extract the data value
      var props = JSON.parse(_articleWall.dataset.jsProps);
      articleWall.init(breakpoint, _articleWall, props);
    });
  }

  /**
   * create muliple instances of product filter
   */
  initProductFilter(breakpoint, overlay) {
    this.productFilter = document.querySelectorAll('.js-product-filter');

    // Map over each article wall
    this.productFilter.forEach((_productFilter) => {
      const productFilter = new ProductFilter();

      // extract the data value
      var props = JSON.parse(_productFilter.dataset.jsProps);
      productFilter.init(breakpoint, _productFilter, props, overlay);
    });
  }

  /**
   * create muliple instances of scroll-sequence
   */
  initImageSequence(breakpoint) {
    this.imageSequence = document.querySelectorAll('.js-image-sequence');


    // Map over each article wall
    this.imageSequence.forEach((_imageSequence) => {
      const imageSequence = new ImageSequence();

      // extract the data value
      var props = {};
      if (_imageSequence.dataset.jsProps) {
        props = JSON.parse(_imageSequence.dataset.jsProps);
      }
      imageSequence.init(breakpoint, _imageSequence, props);
    });
  }

  /*
   * Initialization of DevTools
   */
  initDev(_bp) {
    // let backstop = new Backstop();
    let devHelper = new DevHelper();

    // if (/HeadlessChrome/.test(window.navigator.userAgent)) {
    //   backstop.init();
    // }
    devHelper.init(_bp);
    // END DEV
  }
}

let app = new App();
app.init();
/*eslint-enable */
