import $ from 'jquery/dist/jquery.min.js';
//import Modernizr from 'jquery/dist/jquery.min.js';

('use strict');

class MobileNav {
  constructor() {
    // Variables
    this.mobileNavEnable = true;
    this.props = {
      navSelector: '.js-mobile-nav',
      navOpenClass: 'mobile-nav-is-open',
      navActiveClass: 'is-active',
      navToggleSelector: '.js-mobile-nav-toggle, .js-mobile-nav-close',
      navCheckSelector: '.js-mobile-nav-check',
      stickySelector: '.js-sticky-header',
      breakpoint: null
    };
    this.isOpen = false;
    this.windowWidth = null;
    this.timer = undefined;
  }

  init(_bp) {
    var _ = this;

    _.props.breakpoint = _bp;
    _.windowWidth = $(window).width();
    _.$pageBody = $('body');
    _.stickyHeight = _.$pageBody.find(_.props.stickySelector).height();
    _.$mobileNav = _.$pageBody.find(_.props.navSelector);
    _.$mobileNav.height(window.innerHeight - _.stickyHeight);
    _.$navToggle = _.$pageBody.find(_.props.navToggleSelector);

    _.$navToggle.on('click', function() {
      if (!_.mobileNavEnable) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      } else {
        _.toggle();
      }
    });


    _.registerHandler();
  }

  /**
   * Register event handler
   */
  registerHandler() {
    window.addEventListener('resize', () => this.onResize());
  }

  /**
   * Check on window resize if the last measured window height is not equal to the current
   * set the last window height to be the current window height
   */
  onResize() {
    var _ = this;

    _.$mobileNav.height(window.innerHeight - 60);

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      var w = $(window).width();
      var isDesktop = _.props.breakpoint.getDesktop();

      // Check if viewport width has changed
      if (_.windowWidth !== w) {
        if (isDesktop) {
          _.close();
        } else {
          if (_.isOpen) {
            _.open();
          }
        }

        _.windowWidth = w;
      }
    }, 5);
  }

  open() {
    var _ = this;

    _.$mobileNav.addClass(_.props.navActiveClass);
    _.$navToggle.addClass(_.props.navActiveClass);
    _.$pageBody.addClass(_.props.navOpenClass);
    _.isOpen = true;
  }

  close() {
    var _ = this;

    _.$mobileNav.removeClass(_.props.navActiveClass);
    _.$navToggle.removeClass(_.props.navActiveClass);
    _.$pageBody.removeClass(_.props.navOpenClass);
    _.isOpen = false;
  }

  toggle(e) {
    var _ = this;

    if (!_.mobileNavEnable) {
      _.close();
      return;
    }

    if (!_.isOpen) {
      _.open();
    } else {
      _.close();
    }
  }
}

if(document.querySelector('.is-cv')) {
  document.querySelector('[data-flyout-toggle="#header-flyout-language"]').addEventListener('click', function() {
    document.querySelector('nav.c-mobile-nav').classList.toggle('is-active');
  });
}

export default MobileNav;
