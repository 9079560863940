('use strict');

import 'bootstrap/js/dist/collapse';

class ProductFilter {
  constructor() {
    this.props = {
      breakpoint: null,
      elementSel: '.js-product-filter',
      resultSel: '.js-product-result',
      itemSel: '.js-product',
      openerBtnSel: '.js-opener',
      submitBtnSel: '.js-filter-submit',
      selectSel: '.js-select',
      filterBoxSel: '.js-filter-box',
      inputSel: '.js-filter-input',
      filterBarSel: '.js-filter-bar',
      quantitySel: '.js-product-quantity',
      resetBtnSel: '.js-form-reset',
      resetFacetSel: '.js-facet-reset',
      filterItemSel: '.c-product-filter__item',
      resetFacetTemplate: '<button class="c-product-filter__reset  is-facet  js-facet-reset" type="button"></button>',
      loadmoreSel: '.js-loadmore',
      itemsPerPage: 3,
      activeClass: 'is-active',
      visibleClass: 'is-visible',
      hiddenClass: 'is-hidden',
      sessionStorageFacets: 'coroplast-facets',
      sessionStoragePage: 'coroplast-page'
    };
    this.el = null;
  }

  /**
   * Initialize
   *
   * @param {Breakpoint} _bp
   * @param {obj} _props
   */
  init(_bp, _el, _props, _overlay) {

    if (!_el) return;

    this.el = _el;
    this.props.breakpoint = _bp;
    this.isDesktop = this.props.breakpoint.currentMedia.match(/md|lg|xl|xxl/) ? true : false;
    this.throttleTimer = null;
    this.props = this.setProps(_props);
    this.overlay = _overlay;
    this.overlayActive = false;
    this.body = document.querySelector('body');

    // get elements
    this.resultContainer = this.el.querySelector(this.props.resultSel);
    this.allItems = this.el.querySelectorAll(this.props.itemSel);

    this.filteredResults = null;
    this.loadmoreIndex = 0;
    this.loadmoreFreezedIndex = null;
    this.loadMoreBtn = this.el.querySelector(this.props.loadmoreSel);
    this.openerBtn = this.el.querySelector(this.props.openerBtnSel);
    this.submitBtn = this.el.querySelector(this.props.submitBtnSel);
    this.select = this.el.querySelector(this.props.selectSel);
    this.form = this.el.querySelector('form');
    this.filterBoxes = this.el.querySelectorAll(this.props.filterBoxSel);
    this.filterBar = this.el.querySelector(this.props.filterBarSel);
    this.quantity = this.el.querySelector(this.props.quantitySel);
    this.inputs = this.el.querySelectorAll(this.props.inputSel);
    this.resetBtn = this.el.querySelector(this.props.resetBtnSel);

    // set vars
    this.quantity.innerHTML = this.allItems.length;
    this.ordered = null;
    this.category = this.select.value;
    this.facets = {};
    this.freezedFacets = {};
    this.formId = this.form.getAttribute('id');
    this.lastAction = null;

    // set facets
    this.resetFacets();
    this.checkSavedParams();

    // add event handler
    this.registerHandler();

    // filter the filter with the possible facets from all possible items
    this.allPossibleFacets = this.getAllPossibleItemsFacets();

    // remove filter if not match the facets from all possible items
    this.initialFilterCleanup();

    // filter results
    this.filterResults(this.loadmoreIndex);

    // show filter module when Filter are updated and ready
    this.el.classList.add(this.props.activeClass);
  };

  /**
   *  remove filter if not match the items
   */
  initialFilterCleanup() {
    let removedFilter = [];

    this.filterBoxes.forEach((box) => {
      let id = box.getAttribute('id');
      let isPossible;
      let inputs = box.querySelectorAll(this.props.inputSel);
      let filterBox = box.closest(this.props.filterItemSel);
      let count = inputs.length;

      if (this.allPossibleFacets[id] && this.allPossibleFacets[id].length > 0) {
        inputs.forEach(input => {
          isPossible = false;

          this.allPossibleFacets[id].filter((facet) => {
            if (input.getAttribute('id') === facet) isPossible = true;
          });

          // disable input
          if (isPossible === false) {
            input.parentNode.parentNode.removeChild(input.parentNode);
            count --;
          }
        });

        // remove filter box when all input disabled
        if (count === 0) {
          filterBox.parentNode.removeChild(filterBox);
          removedFilter.push(id);

        } else if (count === 1) {

          // remove filter box when all items have the facet in props
          this.allPossibleFacets[id].filter((facet) => {

            // filter all items
            let itemsFacetCount = 0;

            // only on initial filter
            this.allItems.forEach(_item => {
              let props = JSON.parse(_item.dataset.jsProps);
              for (const [key, value] of Object.entries(props)) {
                props[key].filter((f) => {
                  if (f === facet) {
                    itemsFacetCount ++;
                  }
                });
              }
            });

            if (itemsFacetCount === this.allItems.length) {
              // remove filter box
              filterBox.parentNode.removeChild(filterBox);
              removedFilter.push(id);
            }
          });
        }
      } else {
        // remove filter box
        filterBox.parentNode.removeChild(filterBox);
        removedFilter.push(id);
      }
    });

    removedFilter.forEach(filter => {
      delete this.allPossibleFacets[filter];
    });
  }

  /**
   *  check if facets and paging is saved in session storage
   */
  checkSavedParams() {
    //check if necessary cookies is accept
    if (typeof Cookiebot != 'undefined' &&
      typeof Cookiebot.consent != 'undefined' &&
      typeof Cookiebot.consent.necessary != 'undefined' &&
      Cookiebot.consent.necessary) {

      // if session storage facets
      if (sessionStorage.getItem(this.props.sessionStorageFacets +'-'+ this.formId +'-'+ this.category)) {
        let facetStr = sessionStorage.getItem(this.props.sessionStorageFacets +'-'+ this.formId +'-'+ this.category);
        let facetObj = JSON.parse(facetStr);
        this.facets = facetObj;

        // update inputs
        this.resetInputs(this.facets);
      }

      // if session paging
      if (sessionStorage.getItem(this.props.sessionStoragePage+'-'+ this.formId)) {
        this.loadmoreFreezedIndex = sessionStorage.getItem(this.props.sessionStoragePage+'-'+ this.formId);
      }

    } else {
      sessionStorage.removeItem(this.props.sessionStorageFacets +'-'+ this.formId +'-'+ this.category);
      sessionStorage.removeItem(this.props.sessionStoragePage+'-'+ this.formId);
    }
  }

  /**
   *  set handler
   */
  registerHandler() {
    let that = this;
    this.el.addEventListener('closeOverlay', (e) => this.onCloseOverlay(e));
    this.loadMoreBtn.addEventListener('click', (e) => this.onLoadmoreClick(e));
    this.resetBtn.addEventListener('click', (e) => this.onResetFormClick(e));
    this.openerBtn.addEventListener('click', (e) => this.onOpenOverlay(e));
    this.submitBtn.addEventListener('click', (e) => this.onSubmitClick(e));
    this.select.addEventListener('change', (e) => this.onSelectChange(e));

    // bootstrap collaps events for styling filter box
    // $('.collapse').collapse();
    $(this.props.filterBoxSel).on('show.bs.collapse', function () {
      $(this).parent().addClass(that.props.activeClass);
    });

    $(this.props.filterBoxSel).on('hidden.bs.collapse', function () {
      $(this).parent().removeClass(that.props.activeClass);
    });

    // add eventlistener on document to close the filterbox by click aside
    document.addEventListener('click', (e) => that.onClickDocument(e));
    window.addEventListener('resize', () => this.onResize());

    // loop though all inputs and add event listener on change
    [...this.inputs].forEach(input => input.addEventListener('change', (e) => this.onInputChange(e)));
  }

  /**
   * Check on window resize if the last measured window height is not equal to the current
   * set the last window height to be the current window height
   */
  onResize() {
    // clear timer
    clearTimeout(this.throttleTimer);

    // restart timer
    this.throttleTimer = setTimeout(() => {
      if (this.isDesktop !== this.props.breakpoint.currentMedia.match(/md|lg|xl|xxl/) ? true : false) {

        // set is desktop to variable
        this.isDesktop = this.props.breakpoint.currentMedia.match(/md|lg|xl|xxl/) ? true : false;

        // move content back to desktop view
        if (this.isDesktop === true && this.overlayActive) {
          // Append form element after opener button
          this.openerBtn.after(this.form);
          this.overlayActive = false;
        }
      }
    }, 50);
  }

  /**
   *  event handler
   *
   *  @param {event} e
   */
  onClickDocument(e) {
    let closestFilterBox = e.target.closest(this.props.filterBoxSel);
    if (closestFilterBox === null) {
      $(this.props.filterBoxSel).collapse('hide');
    }
  }

  /**
   *  event handler
   *
   *  @param {event} e
   */
  onOpenOverlay(e) {
    this.overlayActive = true;
    this.loadmoreFreezedIndex = this.loadmoreIndex;

    let obj = {};
    obj.element = this.form;
    obj.response = this.el;
    this.overlay.render(obj);

    // freeze facets to reset the filter state
    this.freezedFacets = {};
    for (let facets in this.facets) {
      this.freezedFacets[facets] = this.facets[facets].filter((item) => {
        return item;
      });
    }
  }

  /**
   *  event handler
   *
   *  @param {event} e
   */
  onCloseOverlay(e) {
    // reset the filter results to state before open the overlay
    this.resetInputs(this.freezedFacets);

    this.facets = {};
    for (let facets in this.freezedFacets) {
      this.facets[facets] = this.freezedFacets[facets].filter((item) => { return item; });
    }

    this.filterResults();
  }

  /**
   *  event handler
   *
   *  @param {event} e
   */
  onSubmitClick(e) {
    e.preventDefault();

    this.loadmoreFreezedIndex = null;
    this.overlay.close(false);
  }

  /**
   *  event handler
   *
   *  @param {event} e
   */
  onLoadmoreClick(e) {
    this.loadmoreIndex ++;
    this.loadnext(this.loadmoreIndex);
  }

  /**
   *  event handler
   *
   *  @param {event} e
   */
  onResetFormClick(e) {
    this.resetFacets();
    this.removeFacetBtn('reset');
    this.resetInputs(this.facets);

    this.resetBtn.setAttribute('disabled', 'disabled');
    this.filterResults();
  }

  /**
   *  event handler
   *
   *  @param {event} e
   */
  onResetFacetClick(e) {
    let value = e.target.getAttribute('value');
    let label = e.target.dataset.facetLabel;

    this.updateInput(value);
    this.removeFacetBtn(value);

    // update facets
    this.facets[label] = this.facets[label].filter(facet => facet !== value);
  }

  /**
   *  event handler
   *
   *  @param {event} e
   */
  onInputChange(e) {
    let input = e.target;
    let value = input.getAttribute('name');
    let name = input.labels[0].textContent;
    let label = input.dataset.facetLabel;
    let facetName = input.dataset.facetName;
    let add = false;

    // update selected facets
    if (input.checked) {
      this.createFacetBtn(name, value, label, facetName);
      add = true;

    } else {
      this.removeFacetBtn(value);
    }

    this.updateFacets(add, label, value);
    this.filterResults();
  }

  /**
   *  update facet filter object
   *
   *  @param {boolean} add
   *  @param {string} value
   *  @param {string} value
   */
  updateFacets(add, label, value) {
    if (add) {
      this.facets[label].push(value);
    } else {
      this.facets[label] = this.facets[label].filter(facet => facet !== value);
    }
  }

  /**
   *  update input checked status
   *
   *  @param {sting} name
   */
  updateInput(name) {
    // loop though all inputs and update input
    this.inputs.forEach(input => {
      if (input.getAttribute('name') === name) {
        input.checked = false;
      }
    });
  }

  /**
   *  event handler
   *
   *  @param {event} e
   */
  onSelectChange(e) {
    let url = this.form.getAttribute('action') + this.select.value;

    // load category page
    window.location.href = url;
  }

  /**
   * create facet reset button
   *
   * @param {string} name
   * @param {string} value
   * @param {string} label
   * @param {string} facetName
   */
  createFacetBtn(name, value, label, facetName) {
    let facet = new DOMParser().parseFromString(this.props.resetFacetTemplate, 'text/html');
    facet.body.firstChild.innerHTML = facetName + ': ' + name; //'<em>' + label + ':</em> ' + name;
    facet.body.firstChild.setAttribute('value', value);
    facet.body.firstChild.setAttribute('data-facet-label', label);

    // add event handler
    facet.body.firstChild.addEventListener('click', (e) => this.onResetFacetClick(e));

    this.filterBar.append(facet.body.firstChild);
  }

  /**
   * remove facet reset button
   *
   * @param {string} value
   */
  removeFacetBtn(value) {
    let facetBtns = this.filterBar.querySelectorAll(this.props.resetFacetSel);
    facetBtns.forEach((facet) => {
      if (facet.getAttribute('value') === value || value === 'reset') {

        // remove event handler
        facet.removeEventListener('click', this.onResetFacetClick);

        // update facets
        this.updateFacets(false, facet.dataset.facetLabel, value);

        // delete facet
        facet.parentNode.removeChild(facet);
      }
    });

    this.filterResults();
  }

  /**
   * reset all facets
   */
  resetFacets() {
    this.facets = {};

    // get facet labels
    this.filterBoxes.forEach((box) => {
      this.facets[box.getAttribute('id')] = [];
    });
  }

  /**
   * reset the state of inputs
   *
   * @param {object} freezed
   */
  resetInputs(freezed) {
    this.inputs.forEach((_input) => {
      let check = false;
      let value = _input.getAttribute('name');
      for (let facets in freezed) {
        freezed[facets].filter((_facet) => {
          if (value === _facet) {
            check = true;
          }
        });
      }

      if (_input.checked !== check) {
        if (check === true) {
          let name = _input.labels[0].textContent;
          let label = _input.dataset.facetLabel;
          let facetName = _input.dataset.facetName;
          this.createFacetBtn(name, value, label, facetName);

        } else {
          this.removeFacetBtn(value);
        }
      }
      _input.checked = check;
    });
  }

  /**
   * filter the filter with the possible facets from all items
   *
   * return {object} possibleFilter
   */
  getAllPossibleItemsFacets() {

    let possibleFilter = null;

    // filter the items
    this.allItems.forEach(_item => {
      let props = JSON.parse(_item.dataset.jsProps);
      if (possibleFilter === null) {
        possibleFilter = props;
      } else {
        for (const [key, value] of Object.entries(possibleFilter)) {
          if (props[key]) {
            possibleFilter[key] = [...possibleFilter[key], ...props[key]].unique();
          }
        }
        for (const [key, value] of Object.entries(props)) {
          if (possibleFilter[key]) {
            possibleFilter[key] = [...possibleFilter[key], ...props[key]].unique();
          } else {
            possibleFilter[key] = props[key].unique();
          }
        }
      }
    });
    return possibleFilter;
  }

  /**
   * filter the products
   */
  filterResults() {
    let hasFacets = false;

    // clear results
    this.resultContainer.innerHTML = '';

    // filter the items
    this.filteredResults = [...this.allItems].filter((_item) => {

      let pick = true;
      let props = JSON.parse(_item.dataset.jsProps);

      for (let facets in this.facets) {
        if (this.facets[facets].length > 0) {
          let pickProp = false;
          hasFacets = true;

          this.facets[facets].filter((facet) => {
            if (props[facets]) {
              props[facets].filter((prop) => {
                if (facet === prop) {
                  pickProp = true;
                }
              });
            }
          });

          if (pickProp === false) pick = false;
        }
      }
      // return item if props are correct
      if (pick) {
        return _item;
      }
    });

    // update state of reset button
    hasFacets ? this.resetBtn.removeAttribute('disabled') : this.resetBtn.setAttribute('disabled', 'disabled');

    // count results
    this.quantity.innerHTML = this.filteredResults.length;

    //safe facets in storage check if necessary cookies is accept
    if (typeof Cookiebot != 'undefined' &&
      typeof Cookiebot.consent != 'undefined' &&
      typeof Cookiebot.consent.necessary != 'undefined' &&
      Cookiebot.consent.necessary) {
      sessionStorage.setItem(this.props.sessionStorageFacets +'-'+ this.formId +'-'+ this.category, JSON.stringify(this.facets));
    }

    // reset loadmore counter
    this.loadmoreIndex = 0;
    this.loadnext(this.loadmoreIndex);
  }

  /**
   * show next set of items
   *
   *  @param {number} index
   */
  loadnext(index) {
    this.loadmoreIndex = index;

    let barrier = (this.loadmoreIndex * this.props.itemsPerPage) + this.props.itemsPerPage;

    // display frist 6 items
    this.ordered = [...this.filteredResults].filter((_item, i) => {

      if (i < barrier && i >= (this.loadmoreIndex * this.props.itemsPerPage)) {
        let itemClone = _item.cloneNode(true);
        this.resultContainer.appendChild(itemClone);

        return _item;
      }
    });

    // udpate if loadmore from storage is greater
    if (this.loadmoreFreezedIndex && this.loadmoreFreezedIndex > this.loadmoreIndex) {
      this.loadmoreIndex ++;
      this.loadnext(this.loadmoreIndex);
    } else {
      this.loadmoreFreezedIndex = null;

      // safe loadmore index in storage if Cookiebot.consent.necessary is accept
      if (typeof Cookiebot != 'undefined' &&
        typeof Cookiebot.consent != 'undefined' &&
        typeof Cookiebot.consent.necessary != 'undefined' &&
        Cookiebot.consent.necessary) {
        sessionStorage.setItem(this.props.sessionStoragePage+'-'+ this.formId, this.loadmoreIndex);
      }

      this.showResults(barrier);
    }
  }

  /**
   * show results
   *
   *  @param {number} barrier
   */
  showResults(barrier) {
    let results = this.resultContainer.querySelectorAll(this.props.itemSel);

    setTimeout(() => {

      // show filtered products
      [...results].filter((_item) => {
        _item.classList.add(this.props.visibleClass);
      });

      // show / hide button
      if (this.filteredResults.length <= barrier) {
        this.loadMoreBtn.classList.add(this.props.hiddenClass);

      } else {
        this.loadMoreBtn.classList.remove(this.props.hiddenClass);
      }

      // trigger event to bookmarks
      let event = new Event('updateTeaser', {bubbles: true});
      this.el.dispatchEvent(event);
    }, 10);
  }

  /**
   * merge default props with props form outside
   *
   *  @param {object} _props
   */
  setProps(_props) {
    const props = {
      ...this.props,
      ..._props,
    };

    return props;
  }
}

export default ProductFilter;
