(
  function() {
    // Check if the ValidityState API is supported
    if (!(
      'validity' in HTMLInputElement.prototype
    )) {
      // Polyfill for ValidityState and related methods

      /**
       * Creates a ValidityState object for a field.
       * @param {HTMLElement} field - The field for which to create the ValidityState.
       * @returns {Object} - The ValidityState object.
       */
      function ValidityState(field) {
        // Field value
        const value = field.value;
        // Field type
        const type = field.getAttribute('type') || field.type;
        // Field attributes
        const required = field.hasAttribute('required');
        const minlength = field.getAttribute('minlength');
        const maxlength = field.getAttribute('maxlength');
        const min = field.getAttribute('min');
        const max = field.getAttribute('max');
        const pattern = field.getAttribute('pattern');
        const step = field.getAttribute('step');

        // ValidityState properties
        this.valueMissing = required && !value;
        this.tooShort = minlength && value.length > 0 && value.length < minlength;
        this.tooLong = maxlength && value.length > maxlength;
        this.typeMismatch = false;

        this.patternMismatch = pattern ? !(
          new RegExp('^(' + pattern + ')$').test(value)
        ) : false;

        this.rangeUnderflow = min !== null && value !== '' && parseFloat(value) < parseFloat(min);
        this.rangeOverflow = max !== null && value !== '' && parseFloat(value) > parseFloat(max);
        this.stepMismatch = false;
        this.badInput = false;
        this.customError = !!field.validationMessage;

        // Specific validations for certain types
        if (type === 'email') {
          // Simple email validation
          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

          this.typeMismatch = value && !emailPattern.test(value);
        } else if (type === 'url') {
          try {
            new URL(value);
            this.typeMismatch = false;
          } catch(_) {
            this.typeMismatch = !!value;
          }
        } else if (type === 'number') {
          // Check if the input is a number
          this.badInput = value && isNaN(value);

          // Check stepMismatch
          if (step && !this.badInput && !this.rangeUnderflow && !this.rangeOverflow) {
            const stepValue = parseFloat(step);
            const minValue = min ? parseFloat(min) : 0;
            this.stepMismatch = (
              (
                parseFloat(value) - minValue
              ) % stepValue
            ) !== 0;
          }
        }

        // Overall validity
        this.valid = !(
          this.valueMissing ||
          this.typeMismatch ||
          this.patternMismatch ||
          this.tooLong ||
          this.tooShort ||
          this.rangeUnderflow ||
          this.rangeOverflow ||
          this.stepMismatch ||
          this.badInput ||
          this.customError
        );
      }

      // Polyfill for the validity property
      Object.defineProperty(HTMLInputElement.prototype, 'validity', {
        get: function() {
          return new ValidityState(this);
        }
      });

      Object.defineProperty(HTMLTextAreaElement.prototype, 'validity', {
        get: function() {
          return new ValidityState(this);
        }
      });

      Object.defineProperty(HTMLSelectElement.prototype, 'validity', {
        get: function() {
          return new ValidityState(this);
        }
      });

      // Polyfill for the checkValidity() method
      HTMLInputElement.prototype.checkValidity = function() {
        return this.validity.valid;
      };

      HTMLTextAreaElement.prototype.checkValidity = function() {
        return this.validity.valid;
      };

      HTMLSelectElement.prototype.checkValidity = function() {
        return this.validity.valid;
      };

      // Polyfill for the setCustomValidity() method
      HTMLInputElement.prototype.setCustomValidity = function(message) {
        this.validationMessage = message;
        this.validity.customError = !!message;
      };

      HTMLTextAreaElement.prototype.setCustomValidity = function(message) {
        this.validationMessage = message;
        this.validity.customError = !!message;
      };

      HTMLSelectElement.prototype.setCustomValidity = function(message) {
        this.validationMessage = message;
        this.validity.customError = !!message;
      };
    }
  }
)();
