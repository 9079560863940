import * as Swiper from 'swiper/js/swiper.js';
import 'swiper/css/swiper.min.css';

('use strict');

/**
 * @class {Slider} Slider Class specification
 */

class Slider {
  constructor() {
    /**
     * Define default properties
     */
    this.props = {
      controls: '.c-slider__controls',
      controlsShowClass: 'c-slider__controls--show',
      buttonWrap: '.c-slider__button-wrap',
      imageBox: '.c-stage__media-box',
      image: 'img',
      slide: '.swiper-slide',
      activeSlide: '.swiper-slide-active',
      breakpoint: null,
      dark: 'is-dark',
      navigation: {
        nextEl: '.c-slider__button--next',
        prevEl: '.c-slider__button--prev'
      },
      pagination: {
        el: '.c-slider__pagination',
        type: 'bullets',
        clickable: true
      },
      watchActiveIndex: true,
      effect: 'slide',
      loop: true,
      autoplay: false
    };

    this.slider = null;
    this.$controls = null;
    this.$buttonWrap = null;
    this.$imageBox = null;
    this.$image = null;
    this.interval = null;
  }

  /**
   * initialize the swiper plugin
   * more information: http://idangero.us/swiper/api/
   */
  init(_bp, _id, _props) {
    this.props.breakpoint = _bp;

    let selector = '.swiper-container';
    if (_id) {
      selector = `#${_id}`;
    }

    this.props = this.setProps(_props);
    this.slider = new Swiper(selector, this.props);

    if (this.slider.$el) {
      this.getElements();
      this.addEventListener();
      if (this.slider.autoplay) this.addAutoPlayListener();

      this.repositionControls();
    }
  }

  /**
   * merge default props with props form outside
   */
  setProps(_props) {
    const props = {
      ...this.props,
      ..._props,
    };

    return props;
  }

  /**
   * find the needed elements in the context of the slideshow
   */
  getElements() {
    this.$imageBox = this.slider.$el[0].querySelectorAll(this.props.imageBox);
    this.$image = this.slider.$el[0].querySelectorAll(this.props.image);
    this.$controls = this.slider.$el[0].querySelectorAll(this.props.controls);
    this.$buttonWrap = this.slider.$el[0].querySelectorAll(this.props.buttonWrap);
    this.$pagination = this.slider.pagination.$el[0];
  }

  /**
   * Add events
   */
  addEventListener() {

    /**
     * on window resize, align the controls
     */
    window.addEventListener('resize', () => {
      this.repositionControls();
    });

    /**
     * wait for the first image to be loaded
     * If the image has a calculated height call repositionControls
     * function and clear the interval
     */
    if (this.$image[0]) {
      this.$image[0].addEventListener( 'load', () => {
        const interval = setInterval(() => {
          if (this.$image[0].clientHeight !== 0) {
            this.slider.update();
            this.repositionControls();
            this.showControls();
            clearInterval(interval);
          }
        }, 10);
      }, false);
    } else {
      this.slider.update();
      this.repositionControls();
      this.showControls();
    }

    /**
     * check slider on change to switch to dark-mode
     */
    this.slider.on('slideChangeTransitionEnd', () => {
      let $activeSlide = this.slider.$el[0].querySelectorAll(this.props.activeSlide);
      let darkMode = $activeSlide[0] && $activeSlide[0].classList.contains(this.props.dark) ? true : false;

      darkMode ? this.slider.el.classList.add(this.props.dark) : this.slider.el.classList.remove(this.props.dark);
    });
  }

  /**
   * Add events for autoplay funcitonallity
   */
  addAutoPlayListener() {
    /**
     * when the user hovers above the slider, let the autoplay stop
     */
    this.slider.$el[0].addEventListener('mouseenter', () => {
      this.slider.autoplay.stop();
    });

    /**
     * when the users  mouse leaves the slider,
     * let the autoplay start again
     */
    this.slider.$el[0].addEventListener('mouseleave', () => {
      this.slider.autoplay.start();
    });
  }

  /**
   * show controls
   */
  showControls() {
    this.$controls[0].classList.add(this.props.controlsShowClass);
  }

  /**
   * the controls have to be aligned vertically
   * according to the image
   */
  repositionControls() {
    if (this.$imageBox[0]) {
      this.$controls[0].style.top = `${this.$imageBox[0].clientHeight}px`;
    }
  }
}

export default Slider;
