import $ from 'jquery/dist/jquery.min.js';

('use strict');

class Search {
  constructor() {
    this.props = {
      searchSel: '.js-search',
      inputSel: 'input[type="search"]',
      submitSel: 'button[type="submit"]'
    };
    this.$el = null;
  }

  /**
   * Initialize
   *
   * @param {Dom Element} _$el
   */
  init(_$el) {
    this.$el = _$el;

    this.$input = this.$el.find(this.props.inputSel);
    this.$submit = this.$el.find(this.props.submitSel);

    // add event handler
    this.registerHandler();

    // check input
    this.onInputChange(null);
  }

  /**
   *  set handler
   */
  registerHandler() {
    this.$input.on('change', (e) => this.onInputChange(e));
    this.$input.on('keyup', (e) => this.onInputChange(e));
  }

  /**
   *  event handler
   *
   *  @param {event} e
   */
  onInputChange(e) {
    let query = this.$input.val();

    if (query.length > 2) {
      this.$submit.removeAttr('disabled');
    } else {
      this.$submit.attr('disabled', 'disabled');
    }
  }
}

export default Search;
