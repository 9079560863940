class Canvas {
  constructor(props) {
    this.images = props.images;
    this.container = props.container;
    this.displayIndex = 0;
  }

  setup() {
    this.canvas = document.createElement('canvas');
    this.container.appendChild(this.canvas);
    this.ctx = this.canvas.getContext('2d');

    this.resize();
  }

  resize() {
    const w = this.container.clientWidth;
    const h = this.container.clientHeight;
    this.canvas.style.height = `${h}px`;
    this.canvas.style.width = `${w}px`;
    this.canvas.height = h;
    this.canvas.width = w;

    this.renderIndex(this.displayIndex);
  }

  renderIndex(e) {
    if (this.images[e]) {
      return this.drawImage(e);
    }
    // Find closest loaded image
    for (var t = Number.MAX_SAFE_INTEGER, r = e; r >= 0; r--)
      if (this.images[r]) {
        t = r;
        break;
      }
    for (var n = Number.MAX_SAFE_INTEGER, i = e, o = this.images.length; i < o; i++)
      if (this.images[i]) {
        n = i;
        break;
      }
    this.images[t] ? this.drawImage(t) : this.images[n] && this.drawImage(n);
  }

  drawImage(index) {
    this.displayIndex = index;
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    // get image
    const img = this.images[index];
    const iw = img.width;
    const ih = img.height;

    const x = 0;
    const y = 0;
    const w = this.ctx.canvas.width;
    const h = this.ctx.canvas.height;

    // default offset is center
    const offsetX = 0.5;
    const offsetY = 0.5;

    const r = Math.min(w / iw, h / ih);
    let nw = iw * r;
    let nh = ih * r;
    let cx = 1;
    let cy = 1;
    let cw = 1;
    let ch = 1;
    let ar = 1;

    // decide which gap to fill
    if (nw < w) ar = w / nw;
    if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;  // updated
    nw *= ar;
    nh *= ar;

    // calc source rectangle
    cw = iw / (nw / w);
    ch = ih / (nh / h);

    cx = (iw - cw) * offsetX;
    cy = (ih - ch) * offsetY;

    // make sure source rectangle is valid
    if (cx < 0) cx = 0;
    if (cy < 0) cy = 0;
    if (cw > iw) cw = iw;
    if (ch > ih) ch = ih;

    // fill image in dest. rectangle
    this.ctx.drawImage(img, cx, cy, cw, ch, x, y, w, h);
  }
}

export default Canvas;
