import $ from 'jquery/dist/jquery.min.js';

('use strict');

class StickyHeader {
  constructor() {
    this.settings = {
      headerSelector: '.js-sticky-header',
      stickyClass: 'is-sticky',
      shrinkClass: 'is-shrunk',
      stickyClassBody: 'header-is-sticky',
      shrinkClassBody: 'header-is-shrunk',

      // After scrolling that many pixels the header becomes sticky.
      stickyOffset: 1,

      // After scrolling that many pixels the header shall scale down.
      shrinkOffset: 200
    };
    this.$header = null;
    this.isShrunk = false;
    this.isSticky = false;
    this.$body = $('body');
  }

  /**
   * Initialize
   */
  init(headerFlyouts, mobileNav) {
    var _ = this;

    _.headerFlyouts = headerFlyouts;
    _.mobileNav = mobileNav;
    _.$header = $(this.settings.headerSelector);

    setTimeout(function() {
      _.getHeight();
      _.setHeaderStates();
      _.initWindowScroll();
    }, 0);

    window.addEventListener('resize', () => this.onResize());
  }

  /**
   * Check on window resize to reset states
   */
  onResize() {
    this.setHeaderStates();
  }

  setStickyState() {
    var _ = this;

    if ($(window).scrollTop() > _.settings.stickyOffset) {
      if (!_.isSticky) {
        _.$header.addClass(_.settings.stickyClass);
        _.$body.addClass(_.settings.stickyClassBody);
        _.isSticky = true;
        _.headerFlyouts.updatePosition();
      }
    } else {
      if (_.isSticky) {
        _.$header.removeClass(_.settings.stickyClass);
        _.$body.removeClass(_.settings.stickyClassBody);
        _.$header.css('top', 0);
        _.isSticky = false;
        _.headerFlyouts.updatePosition();
      }
    }
  }

  setShrinkState() {
    var _ = this;

    if ($(window).scrollTop() > _.settings.shrinkOffset) {
      if (!_.$header.hasClass(_.settings.shrinkClass)) {
        _.$header.addClass(_.settings.shrinkClass);
        _.$body.addClass(_.settings.shrinkClassBody);
        _.isShrunk = true;
        _.headerFlyouts.closeAll();
        _.headerFlyouts.updatePosition();
      }
    } else {
      if (_.$header.hasClass(_.settings.shrinkClass)) {
        _.$header.removeClass(_.settings.shrinkClass);
        _.$body.removeClass(_.settings.shrinkClassBody);
        _.isShrunk = false;
        _.headerFlyouts.updatePosition();
        _.mobileNav.close();
      }
    }
  }

  setHeaderStates() {
    this.setStickyState();
    this.setShrinkState();
  }

  getHeight() {
    this.height = this.$header.outerHeight() || 0;

    return this.height;
  }

  initWindowScroll() {
    var _ = this;

    $(window).scroll(function() {
      _.setHeaderStates();
      _.headerFlyouts.updatePosition();
    });
  }
}

export default StickyHeader;
