('use strict');

/**
 * @class {Breakpoint} Breakpoint Class specification
 */

class Breakpoint {
  constructor() {
    /**
     * Define default properties
     */
    this.props = {
      mq: null,
      isDesktop: null,
      mediaQueryProp: 'font-family',
      head: null
    };
  }

  /**
   * Register event handler
   */
  registerHandler() {
    window.addEventListener('resize', () => this.onResize());
  }

  setMq() {
    this.props.mq = this.currentMedia;
  }

  getMq() {
    return this.props.mq;
  }

  setDesktop() {
    this.props.isDesktop = this.currentMedia.match(/xl|xxl/) ? true : false;
  }

  /**
   * Get Desktop Viewport
   * @returns boolean
   */
  getDesktop() {
    return this.props.isDesktop;
  }

  getCurrentMediaString() {
    return window
      .getComputedStyle(this.props.head, null)
      .getPropertyValue(this.props.mediaQueryProp)
      .replace(/"/g, '');
  }

  /**
   * Check on window resize if the last measured window width is not equal to the current
   * and trigger function rearrangeElems
   * set the last window width to be the current window width
   */
  onResize() {
    const currentMedia = this.getCurrentMediaString();
    if (currentMedia !== this.currentMedia) {
      this.currentMedia = currentMedia;
      console.info('Current media is ' + this.currentMedia);
      this.setMq();
      this.setDesktop();
    }
  }

  init() {
    this.props.head = document.querySelector('head');
    this.currentMedia = this.getCurrentMediaString();
    this.registerHandler();
    this.setMq();
    this.setDesktop();
  }
}

export default Breakpoint;
