/**
 * FormValidator class encapsulates the validation logic for forms.
 */
export class FormValidator {
  /**
   * Constructor for FormValidator.
   * @param {HTMLFormElement} form - The form element to validate.
   */
  constructor(form) {
    this.form = form;
    this.fields = form.querySelectorAll('input, textarea, select');

    // Bind methods
    this.validateField = this.validateField.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.init();
  }

  /**
   * Initializes the form validation by adding event listeners.
   */
  init() {
    this.fields.forEach(field => this.addFieldEventListeners(field));
    this.form.addEventListener('submit', this.handleFormSubmit);
  }

  addFieldEventListeners(field) {
    field.addEventListener('invalid', event => this.handleInvalidFieldEvent(event, field));
    field.addEventListener('input', () => this.validateField(field));
  }

  handleInvalidFieldEvent(event, field) {
    event.preventDefault();
    this.validateField(field);
  }

  /**
   * Handles the form's submit event.
   * @param {Event} event - The submit event.
   */
  handleFormSubmit(event) {
    let firstInvalidField;

    // Check if the form is valid
    if (!this.form.checkValidity()) {
      // Prevent form submission
      event.preventDefault();

      // Validate all fields and display error messages
      this.fields.forEach(field => {
        this.validateField(field);
      });

      // Focus the first invalid field
      firstInvalidField = this.getFirstInvalidField();

      if (firstInvalidField) {
        firstInvalidField.focus();
      }

      this.form.classList.add('was-validated');
    } else {
      event.preventDefault();
      this.onFormSuccess(event);
    }
  }

  getInputType(element) {
    let result = {};

    if (element.tagName === 'INPUT' || element.tagName === 'SELECT' || element.tagName === 'TEXTAREA') {
      result.fieldType = element.tagName.toLowerCase();

      if (result.fieldType === 'input') {
        result.inputType = element.type;
      }

      return result;
    } else {
      return false;
    }
  }

  onFormSuccess(event) {
    try {
      let trackingInputs = this.form.querySelectorAll('[data-tracking]');
      let formId = this.form.getAttribute('id');
      let data = {
        event: 'formSubmissionSuccess',
        formId: formId
      };

      for (let element of trackingInputs) {
        let fieldName = element.getAttribute('data-tracking');
        let types = this.getInputType(element);

        if (fieldName && types) {
          switch (types.fieldType) {
          case 'input':
            data[fieldName] = this.escapeHtml(element.value);
            break;
          case 'select':
            let selectedOption = element.options[element.selectedIndex];
            let selectedLabelText = this.escapeHtml(selectedOption.text);
            let trackingValue = selectedOption.getAttribute('data-tracking-value') ?? selectedLabelText;

            data[fieldName] = trackingValue;
            break;

          default:
            break;
          }
        }
      }

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(data);
    } catch(error) {
      console.error('An error occurred during form submission:', error);
    }

    this.form.submit();
  }

  /**
   * Validates a single field and displays an error message if necessary.
   * @param {HTMLElement} field - The field to validate.
   */
  validateField(field) {
    // Reset previous custom validity message
    field.setCustomValidity('');
    // Remove existing error message from the DOM
    this.removeErrorMessage(field);

    // Check if the field is valid
    if (!field.validity.valid) {
      // Get the specific error message for the ValidityState
      const errorMessage = this.getErrorMessage(field);

      if (errorMessage) {
        // Set the custom validity message
        field.setCustomValidity(errorMessage);
        // Display the error message in the DOM
        this.showErrorMessage(field, errorMessage);
      } else {
        // No custom error message provided, use the browser's default message
        this.showErrorMessage(field, field.validationMessage);
      }
    }
  }

  escapeHtml(input) {
    return input
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;');
  }

  /**
   * Retrieves the error message based on the field's ValidityState.
   * @param {HTMLElement} field - The field for which to get the error message.
   * @returns {string|null} - The error message or null if none is set.
   */
  getErrorMessage(field) {
    const validity = field.validity;
    const validityStates = [
      'valueMissing',
      'typeMismatch',
      'patternMismatch',
      'tooLong',
      'tooShort',
      'rangeUnderflow',
      'rangeOverflow',
      'stepMismatch',
      'badInput',
      'customError'
    ];

    // Iterate over all ValidityState properties
    for (let state of validityStates) {
      if (validity[state]) {
        // Convert camelCase to kebab-case for the data-error attribute
        const dataErrorAttribute = 'data-error-' + state.replace(/([A-Z])/g, '-$1').toLowerCase();
        // Get the error message from the corresponding data-error attribute
        const message = field.getAttribute(dataErrorAttribute);
        if (message) {
          // Return the custom error message
          return message;
        } else {
          // No custom error message provided
          return null;
        }
      }
    }

    // No ValidityState property is true
    return null;
  }

  /**
   * Displays the error message in the DOM.
   * @param {HTMLElement} field - The field for which to display the error message.
   * @param {string} message - The error message to display.
   */
  showErrorMessage(field, message) {
    // Create a span element for the error message
    let errorSpan = document.createElement('span');

    errorSpan.className = 'o-form__error-message';
    errorSpan.id = field.id + '-error';
    errorSpan.textContent = message;

    // Set the aria-describedby attribute for accessibility
    field.setAttribute('aria-describedby', errorSpan.id);

    // Insert the error message after the field
    if (field.type === 'checkbox' || field.type === 'radio') {
      // Special handling for checkboxes and radio buttons
      const parent = field.parentNode;
      parent.parentNode.insertBefore(errorSpan, parent.nextSibling);
    } else {
      field.parentNode.insertBefore(errorSpan, field.nextSibling);
    }
  }

  /**
   * Removes the error message from the DOM.
   * @param {HTMLElement} field - The field for which to remove the error message.
   */
  removeErrorMessage(field) {
    // Remove the error message from the DOM if it exists
    let errorSpan = document.getElementById(field.id + '-error');

    if (errorSpan) {
      errorSpan.parentNode.removeChild(errorSpan);
    }

    // Remove the aria-describedby attribute
    field.removeAttribute('aria-describedby');
  }

  /**
   * Gets the first invalid field from the list of fields.
   * @returns {HTMLElement|null} - The first invalid field or null if all are valid.
   */
  getFirstInvalidField() {
    for (let field of this.fields) {
      if (!field.checkValidity()) {
        return field;
      }
    }

    return null;
  }
}
