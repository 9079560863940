('use strict');

import Numbers from '../helper/numbers';
import ScrollOut from 'scroll-out';
import ImageLoader from './image-loader';
import Canvas from './canvas';

class ImageSequence {
  constructor() {
    this.props = {
      breakpoint: null,
      elementSel: '.js-image-sequence',
      container: 'body',
      starts: 'out',
      ends: 'out',
      images: []
    };
    this.el = null;
    this.images = [];
    this.canvasCreated = false;
    this.scrollout = null;
  }

  /**
   * Initialize
   *
   * @param {Breakpoint} _bp
   * @param {Dom Element} _el
   * @param {obj} _props
   */
  init(_bp, _el, _props) {
    this.el = _el;
    this.props.breakpoint = _bp;
    this.props = {
      ...this.props,
      ..._props,
    };

    this.isDesktop = this.props.breakpoint.currentMedia.match(/lg|xl|xxl/) ? true : false;
    this.imageBox = this.el.querySelector('.c-image-sequence__images-box');
    this.imageContainer = this.el.querySelector('.c-image-sequence__images-container');
    this.scrollWith = this.el.querySelector('.c-image-sequence__container');

    if (this.isDesktop) {
      this.setContainerOffset();
      this.initCanvas();
    }

    // event handler
    this.registerHandler();
  };

  initCanvas() {
    this.createImages();
    this.createCanvas();
    this.addScrollout();

    this.canvasCreated = true;
  }

  changeOnWindowScroll() {
    const numbersHelper = new Numbers();

    if (this.isDesktop) {
      const step = 100 / this.images.length;
      const mapToIndex = numbersHelper.clamp(Math.floor(this.percentScrolled / step), 0, this.images.length - 1);
      requestAnimationFrame(() => this.canvas.renderIndex(mapToIndex));
    }
  }

  get percentScrolled() {
    const { starts, ends } = this.props;
    const container = this.scrollWith;
    const doc = window;

    const clientOffsety = doc.scrollTop || window.pageYOffset;
    const containerHeight = container.clientHeight || container.offsetHeight;
    const clientHeight = doc.clientHeight || window.innerHeight;

    let target = container;
    let offsetY = 0;
    do {
      offsetY += target.offsetTop;
      target = target.offsetParent;
    } while (target && target !== window);

    /*
    console.log('---------------------------');
    console.log('percentScrolled');
    console.log('container', container);
    console.log('clientOffsety', clientOffsety);
    console.log('containerHeight', containerHeight);
    console.log('clientHeight', clientHeight);
    console.log('offsetY', offsetY);
    console.log('---------------------------');
     */

    let u = (clientOffsety - offsetY);
    let d = (containerHeight + clientHeight);

    if (starts === 'out') u += clientHeight;
    if (ends === 'in') d -= clientHeight;
    if (starts == 'in') d -= clientHeight;

    // start: out, ends: out
    // const value = ((clientOffsety + clientHeight) - offsetY) / (clientHeight + containerHeight) * 100;

    //start: in, ends: out
    // const value = (clientOffsety - offsetY) / (containerHeight) * 100;

    //start: out, ends: in
    // const value = ((clientOffsety + clientHeight) - offsetY) / (containerHeight) * 100;

    // Start: in, ends: in
    // (clientOffsety - offsetY) / (containerHeight - clientHeight)

    const value = u / d * 100;
    return value > 100 ? 100 : value < 0 ? 0 : value;
  }

  addScrollout() {
    this.scrollout = ScrollOut({
      scope: this.el,
      targets: '[data-scroll]',
      cssProps: {
        viewportY: true,
        visibleY: true
      }
    });
  }

  removeScrollout() {
    if (this.scrollout) this.scrollout.teardown();
  }

  createCanvas() {
    this.canvas = new Canvas({
      container: this.imageBox,
      images: this.images
    });

    this.canvas.setup();
  }

  setContainerOffset() {
    if (this.imageContainer) {
      const margin = (window.innerHeight - 60 - this.imageContainer.clientHeight) / 2;
      this.imageContainer.style.marginTop = `${margin}px`;
    }
  }

  /**
   *  create Image Array
   */
  createImages() {
    this.images = Array(this.props.images.length);
    this.priorityFrames = [0, 20, 40, 60, 90];

    this.loader = new ImageLoader({
      imgsRef: this.images,
      images: this.props.images,
      priorityFrames: this.priorityFrames
    });

    this.loader.once('FIRST_IMAGE_LOADED', () => {
      this.canvas.renderIndex(0);
    });
    this.loader.once('PRIORITY_IMAGES_LOADED', () => {
      window.addEventListener('scroll', () => this.changeOnWindowScroll());
    });
    this.loader.once('IMAGES_LOADED', () => {
      // console.log('Sequence Loaded');
    });
  }

  /**
   *  register all event handler
   */
  registerHandler() {
    window.addEventListener('resize', () => this.onResize());
  }

  /**
   * Check window resize, and breakpoint change
   */
  onResize() {
    // clear timer
    clearTimeout(this.throttleTimer);

    // restart timer
    this.throttleTimer = setTimeout(() => {

      // resize images in canvas
      if (this.canvas) this.canvas.resize();

      this.setContainerOffset();

      if (this.isDesktop !== this.props.breakpoint.currentMedia.match(/lg|xl|xxl/) ? true : false) {
        // set is-desktop to variable
        this.isDesktop = this.props.breakpoint.currentMedia.match(/lg|xl|xxl/) ? true : false;
      }

      if (!this.canvasCreated && this.isDesktop) {
        this.initCanvas();
      }

      if (this.canvasCreated && !this.isDesktop) {
        this.removeScrollout();
      }
    }, 50);
  }
}
export default ImageSequence;
