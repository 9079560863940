import $ from 'jquery/dist/jquery.min.js';

('use strict');

class HeaderFlyouts {
  constructor() {
    this.flyoutOptions = {
      animationLength: 250,
      breakpoint: null
    };
    this.isSmallScreen = false;
    this.flyouts = new Array();
  }

  init(_bp) {
    var $flyouts = $('.js-header-flyout');
    var _ = this;
    _.flyoutOptions.breakpoint = _bp;

    $flyouts.each(function() {
      var flyout = new HeaderFlyout($(this), _.flyoutOptions);

      flyout.init();
      _.flyouts.push(flyout);
    });
  }

  closeAll() {
    $.each(this.flyouts, function(index, value) {
      var flyout = value;

      flyout.close();
    });
  }

  updatePosition() {
    $.each(this.flyouts, function(index, value) {
      var flyout = value;

      flyout.updatePosition();
    });
  }
}

class HeaderFlyout {
  constructor($flyout, options) {
    this.$flyout = $flyout;
    this.flyoutId = this.$flyout.attr('id');
    this.$body = $('body');
    this.$page = this.$body.find('.js-page');
    this.$pageHead = this.$body.find('.js-page-head');
    this.$meta = this.$body.find('.js-meta-nav');
    this.$toggleButtons = this.$body.find('[data-flyout-toggle=\'#' + this.flyoutId + '\']');
    this.$flyoutContent = this.$flyout.find('.js-header-flyout-content');
    this.$mobileContent = this.$body.find('.js-mobile-nav');
    this.$mobileNav = this.$mobileContent.find('.c-mobile-nav__nav');
    this.isOpen = false;
    this.posY = $(window).scrollTop();
    this.options = options;
    this.openCloseTimer = undefined;
    this.stickyHeight = this.$pageHead.height();
    this.metaHeight = this.$meta.height();
    this.animationIsRunning = false;
  }

  init() {
    var _ = this;
    var flyoutHeight = _.$flyoutContent.outerHeight();
    var w1 = $(window).width();

    _.$flyoutContent.css('margin-top', -1 * flyoutHeight);

    _.$toggleButtons.on('click', function(e) {
      if ($(this).data('flyout-action') === 'close') {
        _.close();
      } else {
        _.toggle();
      }
    });

    $(window).scroll(function() {
      _.posY = $(window).scrollTop();

      if (_.isOpen && !_.animationIsRunning) {
        _.close();
      }
    });

    $(document).mouseup(function(e) {
      var thisFlyoutSelector = '#' + _.flyoutId;
      var $clickedButton = $(e.target).closest('button');
      var flyoutSelector = $clickedButton.attr('data-flyout-toggle');
      var $isToggleButtonClicked =
        flyoutSelector === thisFlyoutSelector;

      if (!_.$flyout.is(e.target) &&
        _.$flyout.has(e.target).length === 0 && !$isToggleButtonClicked && _.isOpen) {
        _.close();
      }
    });
  }

  toggle() {
    this.isOpen ? this.close() : this.open();
  }

  open() {
    var _ = this;
    var isDesktop = _.options.breakpoint.getDesktop();
    var flyoutHeight = _.$flyoutContent.outerHeight();
    var openDelay = _.$body.hasClass('header-flyout-is-closing') ? 50 + _.options.animationLength : 50;

    clearTimeout(this.openCloseTimer);

    _.$toggleButtons.addClass('is-active');
    _.$toggleButtons.attr('aria-expanded', true);
    _.$body.addClass('header-flyout-is-visible');
    _.animationIsRunning = true;

    this.openCloseTimer = setTimeout(() => {
      _.isOpen = true;
      _.$flyoutContent.animate({
        'margin-top': 0
      }, _.options.animationLength);

      // animate only in desktop viewports
      if (isDesktop) {
        _.$pageHead.animate({
          'top': flyoutHeight
        }, _.options.animationLength);
        _.$page.animate({
          'margin-top': flyoutHeight
        }, _.options.animationLength);

      } else {
        // scrollto in mobile viewports
        setTimeout(function () {
          _.$mobileContent[0].offsetHeight;
          let pos = _.$mobileContent[0].scrollHeight;

          if ((window.innerHeight - _.stickyHeight - _.metaHeight) < flyoutHeight) {
            pos = _.$mobileNav.height();
          }

          _.$mobileContent.animate({
            scrollTop: pos
          }, _.options.animationLength);
        }, 50);
      }

      _.animationIsRunning = false;
      _.$flyoutContent.addClass('is-visible');

    }, openDelay);

    if (isDesktop) {
      $(window).scrollTop(0);
    }
  }

  close() {
    var _ = this;
    var flyoutHeight = _.$flyoutContent.outerHeight();

    clearTimeout(this.openCloseTimer);

    _.$flyoutContent.animate({
      'margin-top': -1 * flyoutHeight
    }, _.options.animationLength);

    _.$pageHead.animate({
      'top': 0
    }, _.options.animationLength);

    _.$page.animate({
      'margin-top': 0
    }, _.options.animationLength);

    _.$body.removeClass('header-flyout-is-visible');
    _.$body.addClass('header-flyout-is-closing');

    /* If page is currently being smooth-scrolled, do not interfere. */
    if (!_.$body.hasClass('is-smooth-scrolling')) {
      $(window).scrollTop(_.posY);
    }

    _.$toggleButtons.removeClass('is-active').blur();
    _.$toggleButtons.attr('aria-expanded', false);

    this.openCloseTimer = setTimeout(function() {
      _.$flyoutContent.removeClass('is-visible');
      _.$body.removeClass('header-flyout-is-closing');
    }, _.options.animationLength);

    _.isOpen = false;
  }

  updatePosition() {
    var _ = this;
    var flyoutHeight = _.$flyoutContent.outerHeight();

    if (_.isOpen) {
      _.$flyoutContent.css('margin-top', 0);
      _.$pageHead.css('top', flyoutHeight);
      _.$page.css('margin-top', flyoutHeight);
    }
  }
}

export default HeaderFlyouts;
