('use strict');

import $ from 'jquery/dist/jquery.min.js';

/**
 * This module is responsible to create an overlay
 * without predefining any overlay content.
 */

class Overlay {
  constructor() {
    this.props = {
      breakpoint: null,
      openClass: 'is-open',
      bodyClass: 'is-overlay-open',
      closeBtnSel: '.js-overlay-close',
      overlayClass: 'c-overlay',
      overlaySel: '.js-overlay',
      overlayContentSel: '.js-overlay-content',
      template: '<div class="c-overlay__wrapper">' +
        '<button class="c-overlay__close js-overlay-close"></button>' +
        '<div class="c-overlay__content">' +
        '<div class="c-overlay__inner js-overlay-content"></div>' +
        '</div>' +
        '</div>' +
        '<div class="c-overlay__mask"></div>'
    };
  }

  /**
   * Initialize
   *
   * @param {Breakpoint} _bp
   * @param {obj} _props
   */
  init(_bp, _props) {
    this.props.breakpoint = _bp;
    this.props = this.setProps(_props);

    this.body = document.querySelector('body');

    // append overlay
    this.template = document.createElement('div');
    this.template.classList.add(this.props.overlayClass, this.props.overlaySel.substring(1));
    this.template.innerHTML = this.props.template;
    this.body.append(this.template);

    // Set some references
    this.overlay = document.querySelector(this.props.overlaySel);
    this.closeBtn = document.querySelector(this.props.closeBtnSel);
    this.overlayContent = document.querySelector(this.props.overlayContentSel);

    // response Element
    this.responseElement = null;

    this.overlayCreated = true;
    this.registerHandler();
  }

  /**
   *  set handler
   */
  registerHandler() {
    // Close overlay with ESC
    document.addEventListener('keydown', (e) => {
      if((e.key=='Escape'||e.key=='Esc'||e.keyCode==27) && this.isOpen ){
        e.preventDefault();
        this.close();
      }
    });
  }

  /**
   * Render the overlay
   */
  render(obj) {
    this.contentElement = obj.element;
    this.responseElement = obj.response;

    // Check if data object is provided
    if (!this.contentElement) {
      console.warn('Overlay: You have to provide an object with data (obj.data || obj.options.data)!');
      return;
    }

    // Append element to overlay content
    this.overlayContent.innerHTML = '';
    this.overlayContent.append(this.contentElement);

    // Open overlay
    this.open();
  }

  /**
   * Open Overlay
   */
  open() {
    this.overlay.classList.add(this.props.openClass);
    this.body.classList.add(this.props.bodyClass);
    this.isOpen = true;

    let obj = {};
    obj.element = this.contentElement;
    let openOverlayEvent = new CustomEvent('openOverlay', { bubbles: true, detail: obj });
    this.responseElement.dispatchEvent(openOverlayEvent);

    // Close overlay with ESC
    this.overlay.addEventListener('click', (e) => this.closeOutside(e));
    this.closeBtn.addEventListener('click', (e) => this.close(e));
  }

  /**
   * Close form outside click
   */
  closeOutside(e) {
    let contentElement = e.target.closest(this.props.overlayContentSel);
    if (this.isOpen && e.target.tagName != 'A' && e.target.tagName != 'BUTTON' && contentElement === null) {
      e.preventDefault();
      this.close();
    }
  }

  /**
   * Close overlay
   */
  close(e) {
    // trigger event to product-filter & bookmarks
    if (e) {
      let obj = {};
      obj.element = this.contentElement;
      let closeOverlayEvent = new CustomEvent('closeOverlay', { bubbles: true, detail: obj });

      this.responseElement.dispatchEvent(closeOverlayEvent);
    }

    this.overlay.classList.remove(this.props.openClass);
    this.body.classList.remove(this.props.bodyClass);
    this.isOpen = false;

    // remove overlay content
    this.overlayContent.innerHTML = '';

    // Close overlay by click outside of overlay content
    this.overlay.removeEventListener('click', this.closeOutside);
    this.closeBtn.removeEventListener('click', this.close);
  }

  /**
   * merge default props with props form outside
   */
  setProps(_props) {
    const props = {
      ...this.props,
      ..._props,
    };

    return props;
  }
}

export default Overlay;
