import $ from 'jquery/dist/jquery.min.js';

('use strict');

class MainNav {
  constructor() {
    this.$mainNav = $('.js-main-nav');
    this.$overlay = $('<div class="c-main-nav-overlay"></div>');
    this.$body = $('body');
    this.settings = {
      activeClass: 'is-active',
      openClass: 'is-open',
      openDelay: 0,
      closeDelay: 0
    };
    this.openCloseTimer = undefined;
    this.closeFlyoutTimer = undefined;
    this.flyoutSelector = '.js-main-nav-flyout';
    this.mainNavListSelector = '.js-main-nav-list';
    this.mainNavItemSelector = '.js-main-nav-item';
  }

  init() {
    var _ = this;
    var linkSelectorNoChildren = '.js-main-nav-link:not(.has-children)';
    var $linksNoChildren = _.$mainNav.find(linkSelectorNoChildren);
    var lastTarget;
    var $closeObjects = _.$overlay
      .add($('.js-meta-nav'))
      .add($('.js-page-head'))
      .add($('.js-site-nav'))
      .add($('.js-main-logo'))
      .add($('.js-flyout-container'));

    $linksNoChildren.on('mouseenter focus', function() {
      _.closeNav(_.settings.closeDelay);
    });

    _.$mainNav.mousemove(function(evt) {
      if (evt.target !== lastTarget) {
        lastTarget = evt.target;
        //console.log(lastTarget);
      }
    });

    _.$body.append(_.$overlay);
    _.addFlyout();

    $closeObjects.on('mouseenter', function() {
      _.closeNav(_.settings.closeDelay);
    });
  };

  addFlyout() {
    var _ = this;
    var linkSelector = '.js-main-nav-link.has-children';
    var itemSelector = _.mainNavItemSelector;
    var $links = _.$mainNav.find(linkSelector);
    var openDelay = _.settings.openDelay;

    $links.each(function() {
      var timer;
      var $link = $(this);
      var $parentItem = $link.closest(itemSelector);

      $link.on('mouseenter focus', function() {
        if (!$link.closest(itemSelector)
          .hasClass(_.settings.activeClass)) {
          clearTimeout(this.openCloseTimer);

          timer = setTimeout(function() {
            _.openFlyout($link, itemSelector);
          }, openDelay);
        }
      });

      $parentItem.on('mouseleave', function() {
        if ($parentItem.has('.is-active').length === 0) {

          clearTimeout(this.openCloseTimer);

          clearTimeout(timer);
          _.closeFlyout($link, itemSelector);
        }
      });
    });
  }

  openFlyout($link, itemSelector) {
    const _ = this;
    const items = _.$mainNav.find(itemSelector);
    const $thisFlyout = $link.closest(itemSelector).find(_.flyoutSelector);
    const $otherFlyouts = items.find(_.flyoutSelector).not($thisFlyout);

    setTimeout(function() {
      _.$mainNav.addClass(_.settings.openClass);
      _.$body.addClass('main-nav-is-open');
      _.$overlay.addClass('is-visible');

      items.removeClass(_.settings.activeClass);
      $otherFlyouts.delay(100).stop().fadeOut(100);

      $link.closest(itemSelector).addClass(_.settings.activeClass);
      $thisFlyout.stop().fadeIn(100);
      $link.closest(_.mainNavListSelector)
        .addClass(_.settings.activeClass);
    }, _.settings.openDelay);
  }

  closeFlyout($link, itemSelector) {
    var _ = this;
    var items = _.$mainNav.find(itemSelector);
    var lists = _.$mainNav.find(_.mainNavListSelector);


    _.closeFlyoutTimer = setTimeout(function() {
      items.find(_.flyoutSelector).delay(100).finish().fadeOut(100);
      items.removeClass(_.settings.activeClass);
      lists.removeClass(_.settings.activeClass);
      _.$body.removeClass('main-nav-is-open');

      setTimeout(function() {
        _.$overlay.removeClass('is-visible');
      }, _.settings.closeDelay / 2);
    }, _.settings.closeDelay / 2);
  }

  closeNav(delay) {
    var _ = this;

    setTimeout(function() {
      _.$mainNav.removeClass(_.settings.openClass);
      _.$mainNav.find(_.mainNavItemSelector).removeClass(_.settings.activeClass);
      _.$mainNav.find(_.flyoutSelector).fadeOut(100);
      _.$mainNav.find(_.mainNavListSelector).removeClass(_.settings.activeClass);
      _.$body.removeClass('main-nav-is-open');

      setTimeout(function() {
        _.$overlay.removeClass('is-visible');
      }, delay);
    }, delay);
  }
}

export default MainNav;
